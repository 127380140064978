import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';

import global1 from '../../pages/global1';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function Sidecr1() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    const changeHandler = () => {
        const instype=global1.instype;
        //alert(instype);
        if(instype=='Affiliated') {
            return (
                <>
             
             <Link to='/viewbos'> Faculty engaged in Academic Council (1.1.3)</Link>
        <Link to='/viewaddoncourse'> Value added course (1.2.2 - 1.2.3)</Link>
        <Link to='/viewexplearning'> Experiential learning (1.3.3)</Link>
        <Link to='/viewevent'> All events</Link>
       
                </>
            )
  
        } else if(instype=='University') {
          return (
              <>
        <Link to='/viewsyllabusrev'>View Syllabus Revision ( 1.1.2 - 1.2.2)</Link>
        <Link to='/viewemployability'> View Course Focus on Employability (1.1.3-1.2.1)</Link>
        <Link to='/viewaddoncourse'> Value added course (1.2.2 - 1.2.3 A, 1.3.2 - 1.3.3)</Link>
        <Link to='/viewexplearning'> Experiential learning (1.3.4)</Link>
        <Link to='/viewevent'> All events</Link>
              </>
          )
            
      } else if(instype=='HealthUniversity') {
        return (
            <>
        <Link to='/viewcbcs'> CBCS Programs (1.2.1)</Link>
        <Link to='/viewsyllabusrev'>View Syllabus Revision (1.1.2)</Link>
        <Link to='/viewemployability'> View Course Focus on Employability (1.1.3)</Link>
        <Link to='/viewaddoncourse'> Value added course (1.3.2 - 1.3.3)</Link>
        <Link to='/viewevent'> All events</Link>
            </>
        )
          
    } else if(instype=='Dual') {
        return (
            <>
        <Link to='/viewcbcs'> CBCS Programs (1.2.2)</Link>
        <Link to='/viewsyllabusrev'>View Syllabus Revision ( 1.1.2 - 1.2.2)</Link>
        <Link to='/viewemployability'> View Course Focus on Employability (1.1.3-1.2.1)</Link>
        <Link to='/viewaddoncourse'> Value added course (1.2.2 - 1.2.3 A, 1.3.2 - 1.3.3)</Link>
        <Link to='/viewexplearning'> Experiential learning (1.3.3 A, 1.3.4)</Link>
        <Link to='/viewevent'> All events</Link>
            </>
        )
          
    } else {
          return (
              <>
            
            <Link to='/viewbos'> Faculty engaged in Academic Council (1.1.3 A, 1.1.2 H)</Link>
        <Link to='/viewcbcs'> CBCS Programs (1.1.2 - 1.2.2 C, 1.2.1 HU, 1.2.2 D)</Link>
        <Link to='/viewsyllabusrev'>View Syllabus Revision ( 1.1.2 - 1.2.2 U-C-D, 1.1.2 HU)</Link>
        <Link to='/viewemployability'> View Course Focus on Employability (1.1.3-1.2.1 U-C-D, 1.1.3 HU)</Link>
        <Link to='/viewaddoncourse'> Value added course (1.2.2 - 1.2.3 A, 1.3.2 - 1.3.3 U-C-D-H-HU)</Link>
        <Link to='/viewexplearning'> Experiential learning (1.3.3 A, 1.3.4 U-C-D, 2.2.5 NBA)</Link>
        <Link to='/viewevent'> All events</Link>
        <hr />
        <div style={{marginBottom: 10}}><b>Nomenclature</b></div>
        A - Affiliated college<br />
        U - University<br />
        D - Dual Mode University<br />
        C- Autonomous institution<br />
        H- Health Science institution<br />
        HU- Health Science university<br />
              </>
          )
  
      }
      };

    



    return (
        // <div></div>
        <div className={classes1.sidebar}>
        <div className={classes1.sidebarinside}>   

        <div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />

            {/* {changeHandler()} */}
    
         
        
        
        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
        {/* <Link to='/viewuniversitymetric'> NAAC University / Autonomous (SSR / AQAR 2020-21)</Link> */}
        {/* <div style={{marginBottom: 10}}><b>KPI, Tasks and Alerts</b></div> 
        <Link to='/viewkpiuser1'> View and Edit KPI and Task Assignments</Link>
        <Link to='/addallkpi1'> Refresh KPI</Link>
        <Link to='/viewkpiuserprint'> Status report (KPI)</Link>
        <Link to='/viewkpigroup'> View KPI groupwise</Link>
        <Link to='/viewalerts'> View alerts</Link> */}
      
        <hr />
        <div style={{marginBottom: 10}}><b>Admin data</b></div> 
        <Link to='/viewnewuniversitymenuadmin'> NAAC University New Framework 2023 </Link>
        <Link to='/viewlegalunimenuadmin'> NAAC Legal University Metrics </Link>
        <Link to='/viewautonomousmenuadmin'> NAAC Autonomous New Framework 2023 </Link>
        <Link to='/viewaffiliatedmenuadmin'> NAAC Affiliated New Framework 2023 </Link>
        <Link to='/viewteacherfellowdoccommentsd'>Accreditation Data and Documents</Link>
        <Link to='/viewnallcoursesadmin'>Additional Integrated metrics</Link>
        <Link to='/viewdepfacdocs'>Accreditation Data Submission Status</Link>
        <Link to='/getstudentcount'>NIRF Data and documents</Link>
        <Link to='/viewnallcoursesprog'>Additional metrics for NBA Engg Programwise</Link>
        <Link to='/viewnexplearningadmin'>NAAC Feb 2023 modified metrics </Link>
        {/* <Link to='/adminunivapr23'> University April 2023</Link>
        <Link to='/adminautonomousnew'> Autonomous April 2023</Link>
        <Link to='/adminlegalnew'> Legal university new</Link>
        <Link to='/adminaffiliatedfeb23'> Affiliated Jan 2023</Link> */}
        <Link to='/adminuniversitymetric'> NAAC University SSR/AQAR 2020-21/June 2022 Framework</Link>
        <Link to='/adminhumetric'> NAAC Health University SSR/AQAR 2020-21/June 2022 Framework</Link>
        <Link to='/adminaffiliatedmetric'> NAAC Affiliated SSR/AQAR 2020-21 (Old framework)</Link>
        <Link to='/adminaffiliatedmetricnew'> NAAC Affiliated SSR New June 2022 Onwards</Link>
        <Link to='/viewhnewprogadmin'> Additional metrics</Link>
        <Link to='/viewnacademicadmin'> Other documents</Link>
        <Link to='/viewnnursing812admin'> Nursing additional documents</Link>

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Graphical report</b></div> 
        <Link to='/reportcriteria1'> Criteria 1</Link>
        <Link to='/dashcriteria2'> Criteria 2</Link>
        <Link to='/dashcriteria3'> Criteria 3</Link>
        <Link to='/dashcriteria4'> Criteria 4</Link>
        <Link to='/dashcriteria5'> Criteria 5</Link>
        <Link to='/dashcriteria6'> Criteria 6</Link>
        <hr /> */}

        <div style={{marginBottom: 10}}><b>Graphical view</b></div> 
        <Link to='/dashcriteria1'> Criteria 1</Link>
        <Link to='/dashcriteria2'> Criteria 2</Link>
        <Link to='/dashcriteria3'> Criteria 3</Link>
        <Link to='/dashcriteria4'> Criteria 4</Link>
        <Link to='/dashcriteria5'> Criteria 5</Link>
        <Link to='/dashcriteria6'> Criteria 6</Link>
        <hr />

        <div style={{marginBottom: 10}}><b>Feedback and Value added courses</b></div> 
        <Link to='/viewfeedbackdata'> Faculty feedback data</Link>
            <Link to='/parentfeedbackanalysis'> Parent feedback analysis</Link>
        <Link to='/curriculumfeedbackoption1'> Curriculum feedback</Link>
        <Link to='/employerfeedbackanalysis'> Employer feedback</Link>
        <Link to='/alumnifeedbakanalysis'> Alumni feedback</Link>
        <Link to='/viewdeptfeedbackcount'> Departmentwise Course Exit feedback student list</Link>
        <Link to='/viewsssqscore'> SSS Question Score</Link>
        <Link to='/viewssscount'> SSS student list</Link>
        <Link to='/viewnsssstudadmin'> SSS data</Link>
        <Link to='/viewssstext'> SSS Text</Link>
        <Link to='/viewvacstudlist'> Value added courses attended</Link>
        <hr />
        <div style={{marginBottom: 10}}><b>Activity report</b></div> 
        <Link to='/viewprojectstatus'> Activity list</Link>
        <Link to='/viewprojectstatusv'> All project activities</Link>
        <Link to='/viewprojectstatusbytaskdep'> Activity list by department and task</Link>
        <Link to='/viewprojectstatusbytask'> Activity list by task</Link>
        <Link to='/viewweeklystatus'> Weekly activity report</Link>
        <Link to='/bulkupdatecomments'> Bulk update comments</Link>
        {/* <Link to='/viewsuperweekly'> Weekly status all</Link> */}
        
        {/* <Link to='/workload'> Create Class</Link>    
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/proctorlist'> Proctor list</Link>
        <Link to='/examenrlist'> Check exam enrollment</Link> */}

        <br /><br /><br /><br /><br />
     

        
        </div>      
        <br /><br /> 

</div>
    );

}

export default Sidecr1;