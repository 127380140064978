import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Mexam';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const collegecoderef=useRef();
const collegeref=useRef();
const coldistrictref=useRef();
const coltyperef=useRef();
const admissionyearref=useRef();
const photoref=useRef();
const emailref=useRef();
const regnoref=useRef();
const rollnoref=useRef();
const studentref=useRef();
const dateofbirthref=useRef();
const genderref=useRef();
const religionref=useRef();
const categoryref=useRef();
const casteref=useRef();
const stateref=useRef();
const nationalityref=useRef();
const mobileref=useRef();
const aadharref=useRef();
const bloodref=useRef();
const departmentref=useRef();
const programcoderef=useRef();
const programref=useRef();
const durationref=useRef();
const streamref=useRef();
const mediumref=useRef();
const shiftref=useRef();
const sectionref=useRef();
const admissiondateref=useRef();
const joiningdateref=useRef();
const counsellingref=useRef();
const studstatusref=useRef();
const admmoderef=useRef();
const lateralref=useRef();
const fatherref=useRef();
const fincomeref=useRef();
const foccuref=useRef();
const motherref=useRef();
const mincomeref=useRef();
const moccuref=useRef();
const guardianref=useRef();
const pmobileref=useRef();
const orphanref=useRef();
const annualincref=useRef();
const cardtyperef=useRef();
const cardnoref=useRef();
const diffableref=useRef();
const diffabtyperef=useRef();
const diffpercentref=useRef();
const udidnoref=useRef();
const firstgradref=useRef();
const spcatref=useRef();
const emisnoref=useRef();
const hscboardref=useRef();
const hscregnoref=useRef();
const languageref=useRef();
const hscmarksref=useRef();
const hscscholarshipref=useRef();
const lastscholref=useRef();
const schoolref=useRef();
const govtschoolref=useRef();
const tamilref=useRef();
const addressref=useRef();
const villageref=useRef();
const studdistrictref=useRef();
const pinref=useRef();
const prevprogref=useRef();
const lastyearref=useRef();
const prevregnref=useRef();
const prevyearref=useRef();
const prevpercentref=useRef();
const previnscoderef=useRef();
const previnsnameref=useRef();
const qualmoderef=useRef();
const hostelref=useRef();
const hjoindateref=useRef();
const hleavedateref=useRef();
const hosteltyperef=useRef();
const bankref=useRef();
const accountref=useRef();
const accnameref=useRef();
const acctyperef=useRef();
const ifscref=useRef();
const branchref=useRef();
const allprogramsref=useRef();
const studbatchref=useRef();

  //const collegecode=global1.collegecode;

  const collegecode1=global1.collegecode;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [faculties, setFaculties] = useState([]);

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    const univid1=global1.univid;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       getproglist();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       
        const aff=allprogramsref.current.value;
        const userarray=aff.split('-');
        const program=userarray[0];
        const programcode=userarray[1];
        const depnew=userarray[2];

        const collegecode2=global1.collegecode;
        const insname=global1.insname;
        const univid=global1.univid;

        //const collegecode=collegecoderef.current.value;
//const college=collegeref.current.value;
const coldistrict=coldistrictref.current.value;
const coltype=coltyperef.current.value;
const admissionyear=admissionyearref.current.value;
const photo=photoref.current.value;
const email=emailref.current.value;
//const regno=regnoref.current.value;
const rollno=rollnoref.current.value;
const student=studentref.current.value;
const dateofbirth=dateofbirthref.current.value;
const gender=genderref.current.value;
const religion=religionref.current.value;
const category=categoryref.current.value;
const caste=casteref.current.value;
const state=stateref.current.value;
const nationality=nationalityref.current.value;
const mobile=mobileref.current.value;
const aadhar=aadharref.current.value;
const blood=bloodref.current.value;
// const department=departmentref.current.value;
//const programcode=programcoderef.current.value;
//const program=programref.current.value;
const duration=durationref.current.value;
const stream=streamref.current.value;
const medium=mediumref.current.value;
const shift=shiftref.current.value;
const section=sectionref.current.value;
const admissiondate=admissiondateref.current.value;
const joiningdate=joiningdateref.current.value;
const counselling=counsellingref.current.value;
const studstatus=studstatusref.current.value;
const admmode=admmoderef.current.value;
const lateral=lateralref.current.value;
const father=fatherref.current.value;
const fincome=fincomeref.current.value;
const foccu=foccuref.current.value;
const mother=motherref.current.value;
const mincome=mincomeref.current.value;
const moccu=moccuref.current.value;
const guardian=guardianref.current.value;
const pmobile=pmobileref.current.value;
const orphan=orphanref.current.value;
const annualinc=annualincref.current.value;
const cardtype=cardtyperef.current.value;
const cardno=cardnoref.current.value;
const diffable=diffableref.current.value;
const diffabtype=diffabtyperef.current.value;
const diffpercent=diffpercentref.current.value;
const udidno=udidnoref.current.value;
const firstgrad=firstgradref.current.value;
const spcat=spcatref.current.value;
const emisno=emisnoref.current.value;
const hscboard=hscboardref.current.value;
const hscregno=hscregnoref.current.value;
const language=languageref.current.value;
const hscmarks=hscmarksref.current.value;
const hscscholarship=hscscholarshipref.current.value;
const lastschol=lastscholref.current.value;
const school=schoolref.current.value;
const govtschool=govtschoolref.current.value;
const tamil=tamilref.current.value;
const address=addressref.current.value;
const village=villageref.current.value;
const studdistrict=studdistrictref.current.value;
const pin=pinref.current.value;
const prevprog=prevprogref.current.value;
const lastyear=lastyearref.current.value;
const prevregn=prevregnref.current.value;
const prevyear=prevyearref.current.value;
const prevpercent=prevpercentref.current.value;
const previnscode=previnscoderef.current.value;
const previnsname=previnsnameref.current.value;
const qualmode=qualmoderef.current.value;
const hostel=hostelref.current.value;
const hjoindate=hjoindateref.current.value;
const hleavedate=hleavedateref.current.value;
const hosteltype=hosteltyperef.current.value;
const bank=bankref.current.value;
const account=accountref.current.value;
const accname=accnameref.current.value;
const acctype=acctyperef.current.value;
const ifsc=ifscref.current.value;
const branch=branchref.current.value;
//const studbatch=studbatchref.current.value;
const regno='NA';

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createamastudnewbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                studbatch:'NA',
                collegecode:collegecode2,
college:insname,
univid:univid,
coldistrict:coldistrict,
coltype:coltype,
admissionyear:admissionyear,
photo:photo,
email:email,
regno:regno,
rollno:rollno,
student:student,
dateofbirth:dateofbirth,
gender:gender,
religion:religion,
category:category,
caste:caste,
state:state,
nationality:nationality,
mobile:mobile,
aadhar:aadhar,
blood:blood,
department:depnew,
programcode:programcode,
program:program,
duration:duration,
stream:stream,
medium:medium,
shift:shift,
section:section,
admissiondate:admissiondate,
joiningdate:joiningdate,
counselling:counselling,
studstatus:studstatus,
admmode:admmode,
lateral:lateral,
father:father,
fincome:fincome,
foccu:foccu,
mother:mother,
mincome:mincome,
moccu:moccu,
guardian:guardian,
pmobile:pmobile,
orphan:orphan,
annualinc:annualinc,
cardtype:cardtype,
cardno:cardno,
diffable:diffable,
diffabtype:diffabtype,
diffpercent:diffpercent,
udidno:udidno,
firstgrad:firstgrad,
spcat:spcat,
emisno:emisno,
hscboard:hscboard,
hscregno:hscregno,
language:language,
hscmarks:hscmarks,
hscscholarship:hscscholarship,
lastschol:lastschol,
school:school,
govtschool:govtschool,
tamil:tamil,
address:address,
village:village,
studdistrict:studdistrict,
pin:pin,
prevprog:prevprog,
lastyear:lastyear,
prevregn:prevregn,
prevyear:prevyear,
prevpercent:prevpercent,
previnscode:previnscode,
previnsname:previnsname,
qualmode:qualmode,
hostel:hostel,
hjoindate:hjoindate,
hleavedate:hleavedate,
hosteltype:hosteltype,
bank:bank,
account:account,
accname:accname,
acctype:acctype,
ifsc:ifsc,
branch:branch,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnamastudnew');
       
    };


    

    const getproglist = async () => {
        //setLoading(true);
        
        const response = await ep1.get('/api/v1/affcolprogbycollege', {
            params: {
                colid: colid,
                user: user,
                token: token,
                collegecode: collegecode1

            }

        });
        //setLoading(false);
        console.log(response.data.data);
        setFaculties(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const getval1=(event)=> {
      
        //alert(event.target.value);
        const aff=allprogramsref.current.value;
          const userarray=aff.split('-');
          const user1=userarray[0];
          const uame1=userarray[1];
          const depnew=userarray[2];
          alert('Select program ' + user1 + ' programcode ' + uame1 + ' department ' + depnew);
      
    }

    const combine=(val1,val2,val3)=> {
      
        return val1 + '-' + val2 + '-' + val3;
      
    }

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data for collegecode {collegecode1} for University {univid1}</p>
                        <hr />
                       
                            
                  {/* Enter College Code <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter College Code " id="collegecode" ref={collegecoderef} />
<br /><br /> */}

{/* Enter College Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter College Name" id="college" ref={collegeref} />
<br /><br /> */}

<label htmlFor="admissionyear">Academic Year</label>

<select id="admissionyear" className="form-control"  ref={admissionyearref}>
<option value="2022-23">2022-23</option>
<option value="2023-24">2023-24</option>
</select>
<br /><br />



{/* Enter College  Distict <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter College  Distict " id="coldistrict" ref={coldistrictref} />
<br /><br /> */}

<label htmlFor="coltype">Enter college district</label>

<select id="coldistrict" className="form-control"  ref={coldistrictref}>
<option value="Cuddalore">Cuddalore</option>
<option value="Villupuram">Villupuram</option>
<option value="Kallakurichi">Kallakurichi</option>
<option value="Mayiladuthurai">Mayiladuthurai</option>

</select>
<br /><br />

<label htmlFor="coltype">College Type</label>

<select id="coltype" className="form-control"  ref={coltyperef}>
<option value="Govt">Government</option>
<option value="Govt aided">Aided</option>
<option value="Self financed">Self finance</option>
<option value="Self finance autonomous">Self finance autonomous</option>
<option value="Aided Autonomous">Aided Autonomous</option>

</select>
<br /><br />

<label htmlFor="section">Batch or Section</label>

<select id="section" className="form-control"  ref={sectionref}>
<option value="1">1</option>
<option value="2">2</option>
<option value="3">3</option>
<option value="4">4</option>
</select>
<br /><br />


                    <label htmlFor="shift">Shift</label>

<select id="shift" className="form-control"  ref={shiftref}>
<option value="Shift 1 Day">Shift 1 Day</option>
<option value="Shift 2 Evening">Shift 2 Evening</option>
<option value="No Shift">No Shift</option>
</select>
<br /><br />


<label htmlFor='allprograms'>Select program</label>
                    <select id="allprograms" className="form-control" onChange={getval1}  ref={allprogramsref}>
                    
                    <option value="1">Select program</option>

    {faculties.map((meetup3) => {
                return (
                    <option value={combine(meetup3.program,meetup3.programcode,meetup3.department)}>{meetup3.program} - {meetup3.programcode} - (Department) {meetup3.department}</option>
 
                );
            })}
                  
                    </select>
                    <br />

                    Enter Programme Duration (in years)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="duration" ref={durationref} />
<br /><br />

{/* Enter Programme Stream<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="stream" ref={streamref} />
<br /><br /> */}

<label htmlFor="stream">Program stream </label>

<select id="stream" className="form-control"  ref={streamref}>
<option value="Arts">Arts</option>
<option value="Science">Science</option>
</select>
<br /><br />



{/* Enter Batch<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="studbatch" ref={studbatchref} />
<br /><br /> */}


{/* Enter Programme Code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Programme Code" id="programcode" ref={programcoderef} />
<br /><br />

Enter Programme Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Programme Name" id="program" ref={programref} />
<br /><br /> */}



{/* Enter Medium of Instruction<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="medium" ref={mediumref} />
<br /><br /> */}

<label htmlFor="section">Medium of Instruction</label>

<select id="medium" className="form-control"  ref={mediumref}>
<option value="Tamil">Tamil</option>
<option value="English">English</option>
</select>
<br /><br />



Enter Student Photo URL<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="photo" ref={photoref} />
<br /><br />

Enter Email addrees<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="email" ref={emailref} />
<br /><br />

{/* Enter Student  Registration Number (given by University)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="regno" ref={regnoref} />
<br /><br /> */}

Enter Student Roll No. (given By Collge) <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="rollno" ref={rollnoref} />
<br /><br />

Enter Name of the Student (Capital Letters with initial at the end. For eg. KANNADHASAN D)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="student" ref={studentref} />
<br /><br />

Enter Date of Birth <br /><br />

<input type="date" required style={{width: "100%"}} placeholder="" id="dateofbirth" ref={dateofbirthref} />
<br /><br />

<label htmlFor="gender">Gender </label>

<select id="gender" className="form-control"  ref={genderref}>
<option value="Male">Male</option>
<option value="Female">Female</option>
<option value="Transgender">Transgender</option>
</select>
<br /><br />

Enter Religion <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="religion" ref={religionref} />
<br /><br />

<label htmlFor="category">Category</label>

<select id="category" className="form-control"  ref={categoryref}>
<option value="OC">OC</option>
<option value="BC">BC</option>
<option value="BCM">BCM</option>
<option value="MBC">MBC</option>
<option value="DNT">DNT</option>
<option value="SC">SC</option>
<option value="SCA">SCA</option>
<option value="ST">ST</option>
<option value="EN">EN</option>
</select>
<br /><br />

Enter Caste<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="caste" ref={casteref} />
<br /><br />

{/* Enter State <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="state" ref={stateref} />
<br /><br /> */}

<label htmlFor="state">State</label>

<select id="state" className="form-control"  ref={stateref}>
{/* <option value="Tamil">Tamil</option>
<option value="English">English</option> */}

<option value="Andhra Pradesh">Andhra Pradesh</option>
<option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
<option value="Arunachal Pradesh">Arunachal Pradesh</option>
<option value="Assam">Assam</option>
<option value="Bihar">Bihar</option>
<option value="Chandigarh">Chandigarh</option>
<option value="Chhattisgarh">Chhattisgarh</option>
<option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
<option value="Daman and Diu">Daman and Diu</option>
<option value="Delhi">Delhi</option>
<option value="Lakshadweep">Lakshadweep</option>
<option value="Puducherry">Puducherry</option>
<option value="Goa">Goa</option>
<option value="Gujarat">Gujarat</option>
<option value="Haryana">Haryana</option>
<option value="Himachal Pradesh">Himachal Pradesh</option>
<option value="Jammu and Kashmir">Jammu and Kashmir</option>
<option value="Jharkhand">Jharkhand</option>
<option value="Karnataka">Karnataka</option>
<option value="Kerala">Kerala</option>
<option value="Madhya Pradesh">Madhya Pradesh</option>
<option value="Maharashtra">Maharashtra</option>
<option value="Manipur">Manipur</option>
<option value="Meghalaya">Meghalaya</option>
<option value="Mizoram">Mizoram</option>
<option value="Nagaland">Nagaland</option>
<option value="Odisha">Odisha</option>
<option value="Punjab">Punjab</option>
<option value="Rajasthan">Rajasthan</option>
<option value="Sikkim">Sikkim</option>
<option value="Tamil Nadu">Tamil Nadu</option>
<option value="Telangana">Telangana</option>
<option value="Tripura">Tripura</option>
<option value="Uttar Pradesh">Uttar Pradesh</option>
<option value="Uttarakhand">Uttarakhand</option>
<option value="West Bengal">West Bengal</option>

</select>
<br /><br />


{/* Enter Nationality <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="nationality" ref={nationalityref} />
<br /><br /> */}

<label htmlFor="nationality">Nationality</label>

<select id="nationality" className="form-control"  ref={nationalityref}>
<option value="Indian">Indian</option>
<option value="Foreigner">Foreigner</option>
<option value="NRI">NRI</option>

</select>
<br /><br />

Enter Mobile Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="mobile" ref={mobileref} />
<br /><br />

Enter Aadhar Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="aadhar" ref={aadharref} />
<br /><br />

{/* Enter Blood Group<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="blood" ref={bloodref} />
<br /><br /> */}

<label htmlFor="blood">Blood group</label>

<select id="blood" className="form-control"  ref={bloodref}>

<option value="A+">A+</option>
<option value="A-">A-</option>
<option value="B+">B+</option>
<option value="B-">B-</option>
<option value="O+">O+</option>
<option value="O-">O-</option>
<option value="AB+">AB+</option>
<option value="AB-">AB-</option>
</select>
<br /><br />

{/* Enter Department<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="department" ref={departmentref} />
<br /><br /> */}






Enter Date of Admission to the programme <br /><br />

<input type="date" required style={{width: "100%"}} placeholder="" id="admissiondate" ref={admissiondateref} />
<br /><br />

Enter Date of Joining in the programme <br /><br />

<input type="date" required style={{width: "100%"}} placeholder="" id="joiningdate" ref={joiningdateref} />
<br /><br />

Enter Counselling Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="counselling" ref={counsellingref} />
<br /><br />

<label htmlFor="studstatus">Student status</label>

<select id="studstatus" className="form-control"  ref={studstatusref}>
<option value="Active">Active</option>
<option value="Long absent">Long absent</option>
<option value="Discontinued">Discontinued</option>
</select>
<br /><br />

<label htmlFor="admmode">Admission mode</label>

<select id="admmode" className="form-control"  ref={admmoderef}>
<option value="Government">Government</option>
<option value="Management">Management</option>
</select>
<br /><br />

<label htmlFor="lateral">Lateral Entry</label>

<select id="lateral" className="form-control"  ref={lateralref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Father Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="father" ref={fatherref} />
<br /><br />

Enter Father Income<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="fincome" ref={fincomeref} />
<br /><br />

Enter Father's Occupation<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="foccu" ref={foccuref} />
<br /><br />

Enter Mother Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="mother" ref={motherref} />
<br /><br />

Enter Mother Income<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="mincome" ref={mincomeref} />
<br /><br />

Enter Mother's Occupation<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="moccu" ref={moccuref} />
<br /><br />

Enter Guardian Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="guardian" ref={guardianref} />
<br /><br />

Enter Parent/Guardian Mobile Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="pmobile" ref={pmobileref} />
<br /><br />

<label htmlFor="orphan">Orphan</label>

<select id="orphan" className="form-control"  ref={orphanref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Annual Family Income<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="annualinc" ref={annualincref} />
<br /><br />

Enter Family Card Type<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="cardtype" ref={cardtyperef} />
<br /><br />

Enter Family Card Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="cardno" ref={cardnoref} />
<br /><br />

<label htmlFor="diffable">Differently abled</label>

<select id="diffable" className="form-control"  ref={diffableref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Type of Differently abled (List Encolsed)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="diffabtype" ref={diffabtyperef} />
<br /><br />

Enter Differently abled Percentage<br /><br />

{/* <input type="text" required style={{width: "100%"}} placeholder="" id="diffpercent" ref={diffpercentref} /> */}
<select id="diffpercent" className="form-control"  ref={diffpercentref}>

<option value="0">0</option>
<option value="5">5</option>
<option value="10">10</option>
<option value="15">15</option>
<option value="20">20</option>
<option value="25">25</option>
<option value="30">30</option>
<option value="35">35</option>
<option value="40">40</option>
<option value="45">45</option>
<option value="50">50</option>
<option value="55">55</option>
<option value="60">60</option>
<option value="65">65</option>
<option value="70">70</option>
<option value="75">75</option>
<option value="80">80</option>
<option value="85">85</option>
<option value="90">90</option>
<option value="95">95</option>
<option value="100">100</option>


</select>
<br /><br />

Enter UDID No (Unique Disability ID)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="udidno" ref={udidnoref} />
<br /><br />

<label htmlFor="firstgrad">First Graduate (Y/N)</label>

<select id="firstgrad" className="form-control"  ref={firstgradref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

<label htmlFor="spcat">Special Categories</label>

<select id="spcat" className="form-control"  ref={spcatref}>
<option value="NA">Not applicable</option>
<option value="Sports">Sports</option>
<option value="Ex serviceman">Ex serviceman</option>
</select>
<br /><br />

Enter EMIS No.(Education Management Information System)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="emisno" ref={emisnoref} />
<br /><br />

Enter Board of Hsc Passed<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="hscboard" ref={hscboardref} />
<br /><br />

Enter HSc Registration No.<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="hscregno" ref={hscregnoref} />
<br /><br />

Enter Language Studied in Hsc other than English <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="language" ref={languageref} />
<br /><br />

Enter HSc % of Markes <br /><br />

<input type="number" required style={{width: "100%"}} placeholder="" id="hscmarks" ref={hscmarksref} />
<br /><br />

Enter HSc Scholarship Received if any<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="hscscholarship" ref={hscscholarshipref} />
<br /><br />

Enter Last Scholarship Received if any<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="lastschol" ref={lastscholref} />
<br /><br />

Enter Name of the School Studied (HSc)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="school" ref={schoolref} />
<br /><br />

<label htmlFor="govtschool">6th Std to 12th Std studied at Govt School only (Yes/No)</label>

<select id="govtschool" className="form-control"  ref={govtschoolref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

<label htmlFor="tamil">6th Std to 12th Std studied in Tamil Medium only (Yes/No)</label>

<select id="tamil" className="form-control"  ref={tamilref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Student Address   Door No.  Street Name <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="address" ref={addressref} />
<br /><br />

Enter Name of the Village/Town/City<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="village" ref={villageref} />
<br /><br />

Enter District<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="studdistrict" ref={studdistrictref} />
<br /><br />

Enter Pincode<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="pin" ref={pinref} />
<br /><br />

Enter Previous Programme name and discipline (For. E g.B.A.)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="prevprog" ref={prevprogref} />
<br /><br />

Enter Last year / semester passout <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="lastyear" ref={lastyearref} />
<br /><br />

Enter Previous programme's exam Registration Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="prevregn" ref={prevregnref} />
<br /><br />

<label htmlFor="prevyear">Previous programme's passing year</label>

<select id="prevyear" className="form-control"  ref={prevyearref}>



<option value="2023">2023</option>
<option value="2022">2022</option>
<option value="2021">2021</option>
<option value="2020">2020</option>
<option value="2019">2019</option>
<option value="2018">2018</option>
<option value="2017">2017</option>
<option value="2016">2016</option>
<option value="2015">2015</option>
<option value="2014">2014</option>
<option value="2013">2013</option>
<option value="2012">2012</option>
<option value="2011">2011</option>
<option value="2010">2010</option>


</select>
<br /><br />

Enter Previous programme's percentage of marks <br /><br />

<input type="number" required style={{width: "100%"}} placeholder="" id="prevpercent" ref={prevpercentref} />
<br /><br />

Enter Previous institution code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="previnscode" ref={previnscoderef} />
<br /><br />

Enter Previous institution name <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="previnsname" ref={previnsnameref} />
<br /><br />

<label htmlFor="qualmode">Last qualification mode of study</label>

<select id="qualmode" className="form-control"  ref={qualmoderef}>
<option value="Regular">Regular</option>
<option value="Part time">Part time</option>
<option value="Distance">Distance</option>
</select>
<br /><br />

<label htmlFor="hostel">Hosteller</label>

<select id="hostel" className="form-control"  ref={hostelref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Date of Joining (Hostel)<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="" id="hjoindate" ref={hjoindateref} />
<br /><br />

Enter Date of Leaving (Hostel)<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="" id="hleavedate" ref={hleavedateref} />
<br /><br />

<label htmlFor="hosteltype">Hostel type</label>

<select id="hosteltype" className="form-control"  ref={hosteltyperef}>
<option value="Free">Free</option>
<option value="Paid">Paid</option>
</select>
<br /><br />

Enter Bank Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="bank" ref={bankref} />
<br /><br />

Enter Bank Account No.<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="account" ref={accountref} />
<br /><br />

Enter Bank Account Name <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="accname" ref={accnameref} />
<br /><br />

Enter Bank Account Type<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="acctype" ref={acctyperef} />
<br /><br />

Enter IFSC Code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="ifsc" ref={ifscref} />
<br /><br />

Enter Bank Branch<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="" id="branch" ref={branchref} />
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;