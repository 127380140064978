import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sadditional1';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const affiliationref=useRef();
const programref=useRef();
const typeref=useRef();
const yearref=useRef();
const sanctionref=useRef();
const requestedref=useRef();
const commnoref=useRef();
const apptyperef=useRef();

const allprogramsref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [faculties, setFaculties] = useState([]);

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }

    const getproglist = async () => {
        //setLoading(true);

        const collegecode1=global1.collegecode;
        
        const response = await ep1.get('/api/v1/affcolprogbycollege', {
            params: {
                colid: colid,
                user: user,
                token: token,
                collegecode: collegecode1

            }

        });
        //setLoading(false);
        console.log(response.data.data);
        setFaculties(response.data.data.classes);
       
        //alert(results.length);
       
    };


    useEffect(() => {
       //logout();
       getproglist();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {


        // const aff=allprogramsref.current.value;
        // const userarray=aff.split('-');
        // const program=userarray[0];
        // const programcode=userarray[1];
        // const depnew=userarray[2];

        const program=programref.current.value;
       

        const affiliation=affiliationref.current.value;
//const program=programref.current.value;
const type=typeref.current.value;
const year=yearref.current.value;
const sanction=sanctionref.current.value;
const requested=sanctionref.current.value;
const commno=commnoref.current.value;
const apptype=apptyperef.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createaauc2byfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                affiliation:affiliation,
program:program,
type:type,
year:year,
sanction:sanction,
requested:requested,
commno:commno,
apptype:apptype,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnaauc2');
       
    };

    const getval1=(event)=> {
      
        //alert(event.target.value);
        const aff=allprogramsref.current.value;
          const userarray=aff.split('-');
          const user1=userarray[0];
          const uame1=userarray[1];
          const depnew=userarray[2];
          alert('Select program ' + user1 + ' programcode ' + uame1 + ' department ' + depnew);
      
    }

    const combine=(val1,val2,val3)=> {
      
        return val1 + '-' + val2 + '-' + val3;
      
    }

   
           const width=window.innerWidth;
           const mdivwidth=width - 130;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  <label htmlFor="affiliation">Nature of affiliation</label>

<select id="affiliation" className="form-control"  ref={affiliationref}>
<option value="CPA">CPA</option>
<option value="PA">PA</option>
<option value="PII">PII</option>
</select>
<br /><br />


{/* <label htmlFor='allprograms'>Select program</label>
                    <select id="allprograms" className="form-control" onChange={getval1}  ref={allprogramsref}>
                    
                    <option value="1">Select program</option>

    {faculties.map((meetup3) => {
                return (
                    <option value={combine(meetup3.program,meetup3.programcode,meetup3.department)}>{meetup3.program} - {meetup3.programcode} - (Department) {meetup3.department}</option>
 
                );
            })}
                  
                    </select> */}
                    {/* <br /> */}

Enter Program<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Program" id="program" ref={programref} />
<br /><br />

Enter Type (Regular / Additional)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Type (Regular / Additional)" id="type" ref={typeref} />
<br /><br />

Enter Year of starting<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Year of starting" id="year" ref={yearref} />
<br /><br />

Enter Sanctioned strength<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Sanctioned strength" id="sanction" ref={sanctionref} />
<br /><br />

{/* Enter Strength requested<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Strength requested" id="requested" ref={requestedref} />
<br /><br /> */}

Enter University communication no<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter University communication no" id="commno" ref={commnoref} />
<br /><br />

<label htmlFor="apptype">Medium</label>

<select id="apptype" className="form-control"  ref={apptyperef}>
<option value="English">English</option>
<option value="Tamil">Tamil</option>
<option value="Hindi">Hindi</option>
</select>
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;