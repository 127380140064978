import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sadditional1';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const inscoderef=useRef();
const institutionref=useRef();
const admyearref=useRef();
const departmentref=useRef();
const programcoderef=useRef();
const programref=useRef();
const lateralref=useRef();
const sectionref=useRef();
const rollnoref=useRef();
const regnoref=useRef();
const studentref=useRef();
const genderref=useRef();
const categoryref=useRef();
const nationalityref=useRef();
const studstatusref=useRef();
const mediumref=useRef();
const aadharref=useRef();
const emailref=useRef();
const mobileref=useRef();
const emisref=useRef();
const hscboardref=useRef();
const ugunivref=useRef();
const diffabledref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const inscode=inscoderef.current.value;
const institution=institutionref.current.value;
const admyear=admyearref.current.value;
const department=departmentref.current.value;
const programcode=programcoderef.current.value;
const program=programref.current.value;
const lateral=lateralref.current.value;
const section=sectionref.current.value;
const rollno=rollnoref.current.value;
const regno=regnoref.current.value;
const student=studentref.current.value;
const gender=genderref.current.value;
const category=categoryref.current.value;
const nationality=nationalityref.current.value;
const studstatus=studstatusref.current.value;
const medium=mediumref.current.value;
const aadhar=aadharref.current.value;
const email=emailref.current.value;
const mobile=mobileref.current.value;
const emis=emisref.current.value;
const hscboard=hscboardref.current.value;
const uguniv=ugunivref.current.value;
const diffabled=diffabledref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createaustudu1byfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                inscode:inscode,
institution:institution,
admyear:admyear,
department:department,
programcode:programcode,
program:program,
lateral:lateral,
section:section,
rollno:rollno,
regno:regno,
student:student,
gender:gender,
category:category,
nationality:nationality,
studstatus:studstatus,
medium:medium,
aadhar:aadhar,
email:email,
mobile:mobile,
emis:emis,
hscboard:hscboard,
uguniv:uguniv,
diffabled:diffabled,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnaustudu1');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 130;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  Enter College Code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter College Code" id="inscode" ref={inscoderef} />
<br /><br />

Enter College Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter College Name" id="institution" ref={institutionref} />
<br /><br />

<label htmlFor="admyear">Year of Admission</label>

<select id="admyear" className="form-control"  ref={admyearref}>
<option value="2023-24">2023-24</option>
</select>
<br /><br />

Enter Department<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Department" id="department" ref={departmentref} />
<br /><br />

Enter Programme Code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Programme Code" id="programcode" ref={programcoderef} />
<br /><br />

Enter Programme Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Programme Name" id="program" ref={programref} />
<br /><br />

<label htmlFor="lateral">Lateral Entry</label>

<select id="lateral" className="form-control"  ref={lateralref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

<label htmlFor="section">Section</label>

<select id="section" className="form-control"  ref={sectionref}>
<option value="1">1</option>
<option value="2">2</option>
<option value="3">3</option>
<option value="4">4</option>
</select>
<br /><br />

Enter Student Roll No. (given By College)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Student Roll No. (given By College)" id="rollno" ref={rollnoref} />
<br /><br />

Enter Student EMIS No.<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Student Register No." id="regno" ref={regnoref} />
<br /><br />

Enter Name of the Student<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of the Student" id="student" ref={studentref} />
<br /><br />

<label htmlFor="gender">Gender</label>

<select id="gender" className="form-control"  ref={genderref}>
<option value="Male">Male</option>
<option value="Female">Female</option>
<option value="Transgender">Transgender</option>
</select>
<br /><br />

<label htmlFor="category">Category</label>

<select id="category" className="form-control"  ref={categoryref}>
<option value="OC">OC</option>
<option value="BC">BC</option>
<option value="BCM">BCM</option>
<option value="MBC">MBC</option>
<option value="DNC">DNC</option>
<option value="SC">SC</option>
<option value="SCA">SCA</option>
<option value="ST">ST</option>
</select>
<br /><br />

<label htmlFor="nationality">Nationality</label>

<select id="nationality" className="form-control"  ref={nationalityref}>
<option value="Indian">Indian</option>
<option value="Others">Others</option>
</select>
<br /><br />

<label htmlFor="studstatus">Student Status</label>

<select id="studstatus" className="form-control"  ref={studstatusref}>
<option value="Active">Active</option>
<option value="Long Absent">Long Absent</option>
<option value="TC issued">TC issued</option>
</select>
<br /><br />

<label htmlFor="medium">Medium of Instruction</label>

<select id="medium" className="form-control"  ref={mediumref}>
<option value="English">English</option>
<option value="Tamil">Tamil</option>
</select>
<br /><br />

Enter Aadhar Number<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Aadhar Number" id="aadhar" ref={aadharref} />
<br /><br />

Enter Email ID<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Email ID" id="email" ref={emailref} />
<br /><br />

Enter Mobile Number<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Mobile Number" id="mobile" ref={mobileref} />
<br /><br />

Enter EMIS No.<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter EMIS No." id="emis" ref={emisref} />
<br /><br />

<label htmlFor="hscboard">Board of H.Sc. Passed</label>

<select id="hscboard" className="form-control"  ref={hscboardref}>
<option value="TNHSB">TNHSB</option>
<option value="CBSE">CBSE</option>
<option value="ISC">ISC</option>
<option value="Other Board">Other Board</option>
<option value="Foreign Board">Foreign Board</option>
</select>
<br /><br />

<label htmlFor="uguniv">University in which UG Studied</label>

<select id="uguniv" className="form-control"  ref={ugunivref}>
<option value="AU">AU</option>
<option value="Other University">Other University</option>
<option value="Foreign University">Foreign University</option>
</select>
<br /><br />

Enter Differently abled<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Differently abled" id="diffabled" ref={diffabledref} />
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;