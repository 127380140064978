import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';

import global1 from '../../pages/global1';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function Sidecr1() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    const changeHandler = () => {
        const instype=global1.instype;
        //alert(instype);
        if(instype=='Affiliated') {
            return (
                <>
             
             <Link to='/viewbos'> Faculty engaged in Academic Council (1.1.3)</Link>
        <Link to='/viewaddoncourse'> Value added course (1.2.2 - 1.2.3)</Link>
        <Link to='/viewexplearning'> Experiential learning (1.3.3)</Link>
        <Link to='/viewevent'> All events</Link>
       
                </>
            )
  
        } else if(instype=='University') {
          return (
              <>
        <Link to='/viewsyllabusrev'>View Syllabus Revision ( 1.1.2 - 1.2.2)</Link>
        <Link to='/viewemployability'> View Course Focus on Employability (1.1.3-1.2.1)</Link>
        <Link to='/viewaddoncourse'> Value added course (1.2.2 - 1.2.3 A, 1.3.2 - 1.3.3)</Link>
        <Link to='/viewexplearning'> Experiential learning (1.3.4)</Link>
        <Link to='/viewevent'> All events</Link>
              </>
          )
            
      } else if(instype=='HealthUniversity') {
        return (
            <>
        <Link to='/viewcbcs'> CBCS Programs (1.2.1)</Link>
        <Link to='/viewsyllabusrev'>View Syllabus Revision (1.1.2)</Link>
        <Link to='/viewemployability'> View Course Focus on Employability (1.1.3)</Link>
        <Link to='/viewaddoncourse'> Value added course (1.3.2 - 1.3.3)</Link>
        <Link to='/viewevent'> All events</Link>
            </>
        )
          
    } else if(instype=='Dual') {
        return (
            <>
        <Link to='/viewcbcs'> CBCS Programs (1.2.2)</Link>
        <Link to='/viewsyllabusrev'>View Syllabus Revision ( 1.1.2 - 1.2.2)</Link>
        <Link to='/viewemployability'> View Course Focus on Employability (1.1.3-1.2.1)</Link>
        <Link to='/viewaddoncourse'> Value added course (1.2.2 - 1.2.3 A, 1.3.2 - 1.3.3)</Link>
        <Link to='/viewexplearning'> Experiential learning (1.3.3 A, 1.3.4)</Link>
        <Link to='/viewevent'> All events</Link>
            </>
        )
          
    } else {
          return (
              <>
            
            <Link to='/viewbos'> Faculty engaged in Academic Council (1.1.3 A, 1.1.2 H)</Link>
        <Link to='/viewcbcs'> CBCS Programs (1.1.2 - 1.2.2 C, 1.2.1 HU, 1.2.2 D)</Link>
        <Link to='/viewsyllabusrev'>View Syllabus Revision ( 1.1.2 - 1.2.2 U-C-D, 1.1.2 HU)</Link>
        <Link to='/viewemployability'> View Course Focus on Employability (1.1.3-1.2.1 U-C-D, 1.1.3 HU)</Link>
        <Link to='/viewaddoncourse'> Value added course (1.2.2 - 1.2.3 A, 1.3.2 - 1.3.3 U-C-D-H-HU)</Link>
        <Link to='/viewexplearning'> Experiential learning (1.3.3 A, 1.3.4 U-C-D, 2.2.5 NBA)</Link>
        <Link to='/viewevent'> All events</Link>
        <hr />
        <div style={{marginBottom: 10}}><b>Nomenclature</b></div>
        A - Affiliated college<br />
        U - University<br />
        D - Dual Mode University<br />
        C- Autonomous institution<br />
        H- Health Science institution<br />
        HU- Health Science university<br />
              </>
          )
  
      }
      };

    



    return (
        // <div></div>
        <div className={classes1.sidebar}>
        <div className={classes1.sidebarinside}>   

        <div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />

            {/* {changeHandler()} */}
    
         
        
        
        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
        <Link to='/viewuniversitymetricnew'> NAAC University New framework 2023</Link>
        <Link to='/viewlegaluniversitymenu'> Legal University metrics</Link>
        <Link to='/viewautonomousmenu'> NAAC Autonomous New framework 2023</Link>
        <Link to='/viewaffiliatedmenu'> Affiliated Jan 2023</Link>
        <Link to='/viewuniversitymetric'> NAAC University / Autonomous (SSR / AQAR 2020-21)</Link>
        <Link to='/viewaffiliatedmetric'> NAAC Affiliated (SSR / AQAR 2020-21)</Link>
        <Link to='/viewaffiliatedmetricnew'> NAAC Affiliated New (SSR) June 2022 Onwards</Link>
        <Link to='/viewnallcourses'>Additional Integrated metrics </Link>
        <Link to='/viewnexplearning'>NAAC Feb 2023 modified metrics</Link>
        
        <Link to='/viewhnewprog'> Additional documents </Link>
        <Link to='/viewnacademic'> Other documents </Link>
        <Link to='/viewnnursing812'> Nursing additional documents</Link>
        <Link to='/viewhumetric'> Health University additional metrics</Link>
        <Link to='/viewnbaug'> NBA Tier 1 UG Second Cycle</Link>
        <Link to='/viewnbadiploma'> NBA Diploma</Link>
        <Link to='/viewnbamca'> NBA MCA</Link>


        {/* <Link to='/workload'> Create Class</Link>    
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/proctorlist'> Proctor list</Link>
        <Link to='/examenrlist'> Check exam enrollment</Link> */}
     

        
        </div>      
        <br /><br /> 

</div>
    );

}

export default Sidecr1;