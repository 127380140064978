import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Mexam';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const facidref=useRef();
const inscoderef=useRef();
const institutionref=useRef();
const facultyref=useRef();
const designationref=useRef();
const departmentref=useRef();
const qualificationref=useRef();
const mphildateref=useRef();
const phddateref=useRef();
const netdateref=useRef();
const eligdateref=useRef();
const dateofbirthref=useRef();
const typeref=useRef();
const tservicedateref=useRef();
const regservicedateref=useRef();
const dstaffdateref=useRef();
const pinsdateref=useRef();
const ifqualaffref=useRef();
const ifpgref=useRef();
const ugteachingref=useRef();
const pgteachingref=useRef();
const emailref=useRef();
const mobileref=useRef();
const retirementdateref=useRef();
const accountref=useRef();
const bankref=useRef();
const branchref=useRef();
const ifscref=useRef();
const panref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const facid=facidref.current.value;
const inscode=inscoderef.current.value;
const institution=institutionref.current.value;
const faculty=facultyref.current.value;
const designation=designationref.current.value;
const department=departmentref.current.value;
const qualification=qualificationref.current.value;
const mphildate=mphildateref.current.value;
const phddate=phddateref.current.value;
const netdate=netdateref.current.value;
const eligdate=eligdateref.current.value;
const dateofbirth=dateofbirthref.current.value;
const type=typeref.current.value;
const tservicedate=tservicedateref.current.value;
const regservicedate=regservicedateref.current.value;
const dstaffdate=dstaffdateref.current.value;
const pinsdate=pinsdateref.current.value;
const ifqualaff=ifqualaffref.current.value;
const ifpg=ifpgref.current.value;
const ugteaching=ugteachingref.current.value;
const pgteaching=pgteachingref.current.value;
const email=emailref.current.value;
const mobile=mobileref.current.value;
const retirementdate=retirementdateref.current.value;
const account=accountref.current.value;
const bank=bankref.current.value;
const branch=branchref.current.value;
const ifsc=ifscref.current.value;
const pan=panref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createamfacallnbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                facid:facid,
inscode:inscode,
institution:institution,
faculty:faculty,
designation:designation,
department:department,
qualification:qualification,
mphildate:mphildate,
phddate:phddate,
netdate:netdate,
eligdate:eligdate,
dateofbirth:dateofbirth,
type:type,
tservicedate:tservicedate,
regservicedate:regservicedate,
dstaffdate:dstaffdate,
pinsdate:pinsdate,
ifqualaff:ifqualaff,
ifpg:ifpg,
ugteaching:ugteaching,
pgteaching:pgteaching,
email:email,
mobile:mobile,
retirementdate:retirementdate,
account:account,
bank:bank,
branch:branch,
ifsc:ifsc,
pan:pan,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnamfacalln');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  Enter Faculty ID No.<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Faculty ID No." id="facid" ref={facidref} />
<br /><br />

Enter College Code<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter College Code" id="inscode" ref={inscoderef} />
<br /><br />

Enter College Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter College Name" id="institution" ref={institutionref} />
<br /><br />

Enter Faculty Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Faculty Name" id="faculty" ref={facultyref} />
<br /><br />

Enter Designation<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Designation" id="designation" ref={designationref} />
<br /><br />

Enter Department<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Department" id="department" ref={departmentref} />
<br /><br />

Enter Qualification<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Qualification" id="qualification" ref={qualificationref} />
<br /><br />

Enter Date of M.Phil. Completion<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of M.Phil. Completion" id="mphildate" ref={mphildateref} />
<br /><br />

Enter Date of Ph.D. Completion<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Ph.D. Completion" id="phddate" ref={phddateref} />
<br /><br />

Enter Date of Clearance of SLET/NET <br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Clearance of SLET/NET " id="netdate" ref={netdateref} />
<br /><br />

Enter Date of Acquiring Initial Eligibility<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Acquiring Initial Eligibility" id="eligdate" ref={eligdateref} />
<br /><br />

Enter Date of Birth<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Birth" id="dateofbirth" ref={dateofbirthref} />
<br /><br />

<label htmlFor="type">Nature of Employment</label>

<select id="type" className="form-control"  ref={typeref}>
<option value="Government">Government</option>
<option value="Government Aided">Government Aided</option>
<option value="AU Deputed">AU Deputed</option>
<option value="Self">Self</option>
</select>
<br /><br />

Enter Date of Entry into Teaching service<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Entry into Teaching service" id="tservicedate" ref={tservicedateref} />
<br /><br />

Enter Date of Entry into Regular Government/Aided Service<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Entry into Regular Government/Aided Service" id="regservicedate" ref={regservicedateref} />
<br /><br />

Enter Date of Entry as Deputed staff<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Entry as Deputed staff" id="dstaffdate" ref={dstaffdateref} />
<br /><br />

Enter Date of Joining the Present College<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Joining the Present College" id="pinsdate" ref={pinsdateref} />
<br /><br />

<label htmlFor="ifqualaff">Whether Qualification approval is obtainted from Affiliating University</label>

<select id="ifqualaff" className="form-control"  ref={ifqualaffref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

<label htmlFor="ifpg">Do you handle PG Programme in your Department?</label>

<select id="ifpg" className="form-control"  ref={ifpgref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter UG Teaching Experience in years<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter UG Teaching Experience in years" id="ugteaching" ref={ugteachingref} />
<br /><br />

Enter PG Teaching Experience in years<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter PG Teaching Experience in years" id="pgteaching" ref={pgteachingref} />
<br /><br />

Enter Email<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Email" id="email" ref={emailref} />
<br /><br />

Enter Mobile Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Mobile Number" id="mobile" ref={mobileref} />
<br /><br />

Enter Date of Retirement<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Retirement" id="retirementdate" ref={retirementdateref} />
<br /><br />

Enter Bank Account Number<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Bank Account Number" id="account" ref={accountref} />
<br /><br />

Enter Bank Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Bank Name" id="bank" ref={bankref} />
<br /><br />

Enter Branch Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Branch Name" id="branch" ref={branchref} />
<br /><br />

Enter Bank IFSC CODE<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Bank IFSC CODE" id="ifsc" ref={ifscref} />
<br /><br />

Enter PAN Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter PAN Number" id="pan" ref={panref} />
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;